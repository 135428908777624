@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

body {
  margin: 0;
  font-family: 'Varela Round', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EAEEFF;
}

div {
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 11px;
}

::-webkit-scrollbar:horizontal {
  height: 11px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.react-calendar-heatmap .color-white { fill: #ddd; }

