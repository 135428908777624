.container {
  background-color: #5677FC;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.logo {
  margin-bottom: 48px;
}