.container {
  padding: 8px 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

.text {
  font-size: 18px;
  white-space: nowrap;
}

.container:hover {
  opacity: 0.8;
}

.mainButton {
  background-color: #5677FC;
}

.mainText {
  color: white;
}

.secondaryButton {
  background-color: transparent;
}

.secondaryText {
  color: #5677FC;
}

.navButton {
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.navText {
  color: rgba(0, 0, 0, 0.54);
}

.navButton.selected {
  border-color: #5677FC;
}

.navButton.selected .navText {
  color: #5677FC;
}

.disabled {
  opacity: 0.3;
}

.disabled:hover {
  opacity: 0.3 !important;
}