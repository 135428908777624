.actionContainer {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.actionContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.actionContainer.selected {
  background-color: #5677FC;
}