.container {
  background-color: #d9d9d9;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.input {
  background: transparent;
  outline: none;
  border: none;
  margin-left: 8px;
  font-size: 18px;
  font-family: inherit;
}
