.container {
    height: 100vh;
    min-width: 1000px;
    width: 100%;
    position: relative;
}

.activeCard {
    background-color: white;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
  