.container {
  min-width: 100vw;
  min-height: 100vh;
}

.mainContainer {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  position: relative;
}
