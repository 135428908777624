.container {
  height: 100vh;
  min-width: 1000px;
  width: 100%;
  position: relative;
  overflow: auto;
}

.tag {
  text-decoration: none;
  color: black;
}

.tagContainer {
  background-color: #ddd;
  border-radius: 32px;
  transition: opacity 0.2s;
  user-select: none;
}

.tagContainer:hover {
  opacity: 0.7;
}

.tagsContainer {
  flex-wrap: wrap;
  gap: 12px;
  flex-direction: row;
  height: 168px;
  align-content: flex-start;
  overflow: auto;
}

.textButton {
  color: rgba(0, 0, 0, 0.3);
  padding: 4px 12px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
}

.textButton:hover {
  background-color: #ddd;
}

.textButtonSelected {
  background-color: #ddd;
  color: rgba(0, 0, 0, 1);
}
