.container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  pointer-events: all;
}


.container:hover {
  background: #e7f2f8;
}

.container:hover .menuContainer {
  display: block;
}

.menuContainer {
  position: absolute;
  top: 24px;
  right: -8px;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  border-radius: 4px;
  display: none;
  width: 160px;
}

.row {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.selectedRow {
  font-weight: bolder;
}

.labelRow {
  position: relative;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.row:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.row span {
  font-size: 14px;
  color: black;
  margin-left: 8px;
}

.innerMenuContainer {
  background: white;
  width: 160px;
  position: absolute;
  top: 0;
  right: 160px;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: none;
}

.labelRow:hover .innerMenuContainer {
  display: block;
}

.userLabelsHint {
  font-size: 10px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: 0 !important;
  margin-top: 4px;
}
