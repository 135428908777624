@keyframes load {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EAEEFF;
}

.logo {
  width: 160px;
  height: 160px;
  animation-name: load;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}