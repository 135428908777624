.container {
    background-color: #e6e6e6;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 8px;
  }

.input {
    background: transparent;
    outline: none;
    border: none;
    margin-left: 8px;
    font-family: inherit;
    width: 100%;
  }

  .error {
    outline: 1px solid #E23F33;
    outline-offset: -1px;
  }