.backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  cursor: auto !important;
  visibility: hidden;
  overflow: hidden;
}

.container {
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  transform: translateX(600px);
  transition: all 200ms;
  cursor: auto !important;
  overflow: auto;
}

.display {
  visibility: visible;
}

.display .container {
  transform: translateX(0);
}

.messageContainer {
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.message {
  padding: 8px;
  margin: 0;
  font-family: inherit;
  white-space: pre-wrap;
}

.textareaContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 16px !important;
}

.textareaContainer div {
  margin: 0 !important;
}

.textarea {
  width: 100%;
  height: 120px;
  outline: none;
  border-radius: 4px;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-family: inherit;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  resize: none;
}

.askAiContainer {
  background: #2d2d2d;
  color: white;
  font-size: 12px;
  padding: 8px;
  border-radius: 8px;
  margin-top: 16px;
}

.askAiContainer p {
  margin: 0 !important;
}

.askAiContainer pre {
  background: #1e1e1e;
}

.askAiContainer code {
  background: #1e1e1e;
}
