.base {
    border: transparent;
    font-family: inherit;
    transition: opacity 0.2s;
    cursor: pointer;
    background-color: transparent;
    z-index: 2;
    color: white;
    min-width: 48px;
}

.base:hover {
    opacity: 0.7;
}

.border {
    border-radius: 8px;
}

.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}