.base {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.base:hover .tooltip {
  display: block;
}

.base.disabled:hover .tooltip {
  display: none;
}

.main:hover {
  background: #e7f2f8;
}

.dark:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}

.home {
  background: linear-gradient(to left, #29abe2, #0077ff);
}

.home:hover {
  opacity: 0.8;
}

.white {
  background: transparent;
  color: black;
}

.white:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}

.normal {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.small {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.tiny {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.normalWithText {
  height: 40px;
  border-radius: 20px;
}

.smallWithText {
  height: 32px;
  border-radius: 16px;
  padding: 0 8px;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.home.disabled:hover {
  opacity: 0.3;
}

.main.disabled:hover {
  background: #f6fbfe;
}

.tooltip {
  position: absolute;
  top: 48px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 100;
  border-radius: 8px;
  padding: 4px 8px;
  display: none;
  font-size: 12px;
  text-align: center;
}

.tooltipRight {
  left: 100% !important;
  top: 0 !important;
  margin-left: 8px !important;
}

.small .tooltip {
  transform: translateX(calc(-50% + 16px));
}

.normal .tooltip {
  transform: translateX(calc(-50% + 20px));
}

.small .tooltipRight {
  transform: translateY(calc(50% + 16px)) !important;
}

.normal .tooltipRight {
  transform: translateY(calc(-50% + 20px)) !important;
}

.text {
  white-space: nowrap;
  color: white;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
}
