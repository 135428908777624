.container {
    height: 100vh;
    min-width: 1000px;
    width: 100%;
    position: relative;
    overflow: auto;
}

.options {
    padding: 4px;
}

.boxContainer {
    padding: 16px !important;
    background-color: white;
    align-items: flex-start !important;
    border-radius: 12px;
    gap: 16px;
}

.textButton {
    color: rgba(0, 0, 0, 0.3);
    padding: 4px 12px;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
    text-decoration: none !important;
}

.textButton:hover {
    background-color: #ddd;
}

.textButtonSelected {
    background-color: #ddd;
    color: rgba(0, 0, 0, 1);
}