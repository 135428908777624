.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.courseContainer {
  background-color: white;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}

.lessonsContainer {
  padding: 0 16px 0 64px;
}

.lessonContainer {
  background-color: white;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 16px;
  transition: all 0.3s;
}

.lessonContainer:hover {
  background-color: rgb(245, 245, 245);
}


.itemContainer {
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 16px;
  transition: all 0.3s;
}

.itemContainer:hover {
  background-color: rgb(245, 245, 245);
}

.itemActive {
  background-color: rgb(235, 235, 235);
}

.itemActive:hover {
  background-color: rgb(230, 230, 230);
}

.title {
  font-size: 24px;
  flex: 1;
  margin-left: 16px;
}

.progress {
  font-size: 24px;
}

.progressIndicator {
  border: 2px solid gray;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressIndicatorFilled {
  background-color: gray;
}

.rehearsalIcon {
  width: 20px;
  height: 20px;
  margin-left: 16px;
}

.rehearsalIconNotDone {
  opacity: 0.3;
}

.lessonName {
  flex: 1;
}

.lessonName span {
  opacity: 0.54;
}

.failedNum {
  width: 48px;
  text-align: right;
}

.askAiNum {
  margin-left: 8px;
  color: blue;
}
