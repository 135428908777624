.container {
  padding: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.card {
  background: white;
  border-radius: 8px;
  padding: 16px;
}

.title {
  font-size: 24px;
}
