.container {
  height: 100vh;
  min-width: 1000px;
  width: 100%;
  position: relative;
}

.button {
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  outline: none;
  font-family: inherit;
  background-color: transparent;
  padding: 8px 16px;
  cursor: pointer;
}

.button:hover {
  background-color: #ddd;
  opacity: 0.8;
}

.button.selected {
  background-color: #ddd;
}
