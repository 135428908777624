.activeCard {
  background-color: white;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  height: 280px;
}

.cardContainer {
  min-width: 600px;
  width: 100%;
}

.textButton {
  color: rgba(0, 0, 0, 0.3);
  padding: 4px 12px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  text-decoration: none !important;
}

.textButton:hover {
  background-color: #ddd;
}

.textButtonSelected {
  background-color: #ddd;
  color: rgba(0, 0, 0, 1);
}

.iconButton {
  cursor: pointer;
  border-radius: 20px;
  padding: 4px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
}

.iconButton:hover {
  background: #eee;
}

.iconButtonDisabled {
  opacity: 0.3;
  cursor: auto;
}

.iconButtonDisabled:hover {
  background: none;
}

.clickable:hover {
  opacity: 0.8;
}
