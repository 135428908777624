.container {
  background: white;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.input {
  background: transparent;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 18px;
}

.container:focus-within {
  border: 1px solid rgba(0, 0, 0, 0.3);
}
