.userHeaderRow,
.userRow {
  height: 40px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
}

.userHeaderRow {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #eaeeff;
  border-bottom: 1px solid #d4d4d4;
}

th {
  font-weight: normal;
}

.userRow {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
  background-color: white;
}

.userRow:hover {
  background-color: rgb(240, 240, 240);
}

.mainDiv {
  display: block;
  margin: 16px;
  min-width: 800px;
  width: 100%;
  height: 90vh;
}

.loadingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 16px;
}

.table {
  display: block;
  height: 100%;
  overflow-y: scroll;
  min-width: 800px;
  width: 100%;
}

.ref,
.rating,
.from,
.daysUsed,
.compilesNum,
.aiResponsesNum,
.todayCompilesNum,
.email,
.name,
.streak,
.score,
.createdDate,
.lastActivity {
  font-size: 12px;
  text-align: start;
}

.daysUsed,
.compilesNum,
.todayCompilesNum,
.aiResponsesNum,
.streak,
.score,
.createdDate,
.lastActivity {
  cursor: pointer;
}

.email {
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  width: 88px;
  display: flex;
}

.ref,
.from {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.from {
  width: 48px;
}

.ref {
  width: 40px;
}

.createdDate {
  width: 120px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.lastActivity {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rating {
  width: 48px;
}

.aiResponsesNum,
.daysUsed,
.compilesNum,
.todayCompilesNum,
.streak,
.score {
  width: 64px;
}

.aiResponsesNum,
.compilesNum {
  width: 100px;
}

.todayCompilesNum {
  width: 160px;
}

.selected {
  font-weight: bold;
}

.smileyContainer {
  width: 24px;
  height: 24px;
  position: relative;
}

.feedbackIcon {
  position: absolute;
  right: 0px;
  top: -4px;
}

td b {
  text-decoration: underline dotted;
}