.container {
  height: 480px;
}

.leftContainer {
  width: 480px;
  height: 100%;
}

.rightContainer {
  height: 100%;
  width: 600px;
}

.coursesContainer {
  height: 100%;
  overflow: auto;
}

.courseContainer {
  background: #ddd;
  border-radius: 8px;
  transition: opacity 0.2s;
}

.courseContainer:hover {
  opacity: 0.8;
}
