.base {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.row {
  flex-direction: row;
}

.fitParent {
  height: 100%;
  width: 100%;
}

.border {
  border-radius: 10px;
}


.base::-webkit-scrollbar {
  appearance: none;
}

.base::-webkit-scrollbar:vertical {
  width: 11px;
}

.base::-webkit-scrollbar:horizontal {
  height: 11px;
}

.base::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

