.container {
  background-color: #F4F7FF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  top: 0;
  align-items: center;
}

.logo {
  width: 24px;
  height: 24px;
  margin: 8px;

}

.textLogo {
  font-family: "Audiowide";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  color: #8096F1;
}

.actionsContainer {
  padding-top: 8px;
  flex: 1;
}

.actionsContainer div {
  margin-top: 32px;
}

.Triangles {
  position: absolute;
  z-index: 1;
  bottom: 0;
}