.container {
    height: 100vh;
    min-width: 1000px;
    width: 100%;
    position: relative;
  }
  
  .tableHeaderRow {
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #eaeeff;
    border-bottom: 1px solid #d4d4d4;
  }
  
  .tableHeaderRow,
  .tableRow {
    height: 40px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;
  }
  
  .table {
    display: block;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
  }
  
  .type,
  .title,
  .time {
    font-size: 14px;
    text-align: start;
  }
  
  .type {
    width: 120px;
  }
  
  .title {
    flex: 1;
  }
  
  .time {
    width: 160px;
  }
  
  .tableRow {
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 8px;
    background-color: white;
  }
  
  .tableRow:hover {
    background-color: rgb(240, 240, 240);
  }
  