.tag {
  border-radius: 12px;
  font-size: 12px;
  padding: 4px 8px;
}

.main {
  background-color: #eee;
  color: black;
}

.success {
  background-color: #05B20C;
  color: white;
}

.secondary {
  background-color: #5005b2;
  color: white;
}

.error {
  background-color: tomato;
  color: white;
}

