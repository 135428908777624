.backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  cursor: auto !important;
  visibility: hidden;
  overflow: hidden;
}

.container {
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  transform: translateX(600px);
  transition: all 200ms;
  cursor: auto !important;
  overflow: auto;
}

.display {
  visibility: visible;
}

.display .container {
  transform: translateX(0);
}

.bottomContainer {
  position: sticky;
  bottom: 0;
  background-color: white;
}

.input {
  font-size: 16px;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  font-family: inherit;
}