.container {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 4px 12px !important;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    z-index: 50;
}

.container:hover {
    border-color: rgba(0, 0, 0, 0.6);
}

.popupWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    padding-top: 8px;
    height: 160px;
}

.popup {
    background-color: white;
    border-radius: 8px;
    min-width: 240px;
    height: 152px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.popup div {
    width: 100%;
}

.popup select {
    font-size: 16px;
    padding: 4px 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
    font-family: inherit;
}

.container:hover .popupWrapper {
    visibility: visible;
}