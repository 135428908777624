.container {
  height: 100vh;
  min-width: 1000px;
  width: 100%;
  position: relative;
}

.tableHeaderRow {
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #eaeeff;
  border-bottom: 1px solid #d4d4d4;
}

.tableHeaderRow,
.tableRow {
  height: 40px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
}

.tableRow.leaderboard {
  height: auto;
  padding: 8px;
}

.leaderboardTable,
.groupTable,
.userTable {
  display: block;
  height: 100%;
  overflow-y: scroll;
}

.leaderboardTable {
  width: 280px;
}

.groupTable {
  width: 200px;
}

.userTable {
  flex: 1;
}

.title,
.num {
  font-size: 14px;
  text-align: start;
}

.title {
  flex: 1;
}

.num {
  width: 160px;
}

.tableRow {
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;
}

.tableRow:hover {
  background-color: rgb(240, 240, 240);
}

.tableRow.selected {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.idWidth {
  width: 80px;
}

.valueWidth {
  width: 60px;
}
