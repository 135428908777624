.container {
    height: 100vh;
    min-width: 800px;
    width: 100%;
    position: relative;
}

.cellData {
    width: 140px;
}

.cellDataBig {
    width: 320px;
}

.cellId {
    width: 400px
}