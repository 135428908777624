.MenuButton {
  cursor: pointer;
  border-radius: 10px;
}

.NotSelected {}


.NotSelected:hover {
  background: rgba(0, 0, 0, 0.04);
}


.Selected {
  background: #5677FC11;

}

.Selected:hover {
  background: #5677FC22;
}