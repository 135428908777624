.backdrop {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  cursor: auto !important;
  visibility: hidden;
  overflow: hidden;
}

.container {
  width: 600px;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  transform: translateX(600px);
  transition: all 200ms;
  cursor: auto !important;
  overflow: auto;
}

.display {
  visibility: visible;
}

.display .container {
  transform: translateX(0);
}

.messageContainer {
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.message {
  padding: 8px;
  margin: 0;
  font-family: inherit;
  white-space: pre-wrap;
}

.textareaContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 16px !important;
}

.textareaContainer div {
  margin: 0 !important;
}

.textarea {
  width: 100%;
  height: 120px;
  outline: none;
  border-radius: 4px;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-family: inherit;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  resize: none;
}

.input {
  font-size: 18px;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font-family: inherit;
}

textarea.input {
  height: 120px !important;
}

.disabled {
  opacity: 0.3;
}

.tag {
  text-decoration: none;
  color: black;
}

.tagContainer {
  background-color: #ddd;
  border-radius: 32px;
  transition: opacity 0.2s;
  user-select: none;
}

.tagsContainer {
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: row;
  align-content: flex-start;
  margin-top: 8px !important;
}

.tagsSelectionContainer {
  position: absolute;
  top: 32px;
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 100;
  max-height: 160px;
  overflow: auto;
  visibility: hidden;
}

.tagSelection {
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
}

.tagSelection:hover {
  background: #eee;
}

.selectTagContainer {
  position: relative;
  width: 320px;
}

.selectTagContainer:focus-within .tagsSelectionContainer,
.selectTagContainer:active .tagsSelectionContainer  {
  visibility: visible;
}

.termWeight {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  background: transparent;
  border-radius: 4px;
  width: 48px;
  font-family: inherit;
  box-sizing: border-box;
}
