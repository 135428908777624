.container {
  background-color: #29abe2;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.basicInfoContainer {
  display: flex;
  flex-direction: column;
}

.displayName {
  color: white;
  font-size: 16px;
}

.email {
  margin-top: 2px;
  color: white;
  font-size: 14px;
  opacity: 0.87;
}

.backButton {
  margin-right: 16px;
}

.button {
  border-radius: 8px 8px 0 0;
  margin-right: 16px;
  cursor: pointer;
  color: #777777;
  font-weight: 500;
}

.button.selected {
  color: #5677FC;
  font-weight: 700;
}