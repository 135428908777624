.container {
  background: white;
  width: 800px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
  height: 1000px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
}

.code {
  background-color: #1e1e1e;
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: Droid Sans Mono, monospace, monospace, Droid Sans Fallback;
  margin: 0;
}

.divider {
  min-height: 48px;
  width: 1px;
  border-right: 2px dotted rgba(0, 0, 0, 0.3);
  margin-top: 24px;
  position: relative;
}

.timeDelta {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 12px;
  height: 24px;
  width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  background-color: white;
}

.submissionContainer {
  width: 100%;
  padding: 0 16px;
}

.submissionTopContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  position: relative;
}

.submissionTime {
  margin-left: 8px;
  flex: 1;
}

.submissionIndex {
  margin-left: 8px;
}

.submissionLang {
  margin-left: 8px;
}

.hintSeenContainer {
  padding: 8px 16px;
  margin-top: 16px;
  background-color: #ddd;
  border-radius: 8px;
}

.ace-editor {
  border-radius: 10px;
}

.askAiContainer {
  background: #2d2d2d;
  color: white;
  font-size: 12px;
  padding: 8px;
  margin: 0 16px;
  border-radius: 8px;
}

.askAiContainer p {
  margin: 8px 0 !important;
}

.askAiContainer pre {
  background: #1e1e1e;
}

.askAiContainer code {
  background: #1e1e1e;
}

.fileButton {
  color: #1e1e1e;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #1e1e1e;
}

.fileButton.selected {
  color: white;
  background-color: #1e1e1e;
}

.hintSeenInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
