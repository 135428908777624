.container {
  height: 100vh;
  min-width: 1000px;
  width: 100%;
  position: relative;
}

.tableRow th {
  font-weight: bold;
  background-color: white;
}

.tableRow th,
.tableRow td {
  width: 200px;
  height: 40px;
  text-align: start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  padding-left: 8px;
  box-sizing: border-box;
}

.tableRow th:nth-child(1),
.tableRow td:nth-child(1) {
  width: 32px;
}

.tableRow th:nth-child(2),
.tableRow td:nth-child(2) {
  width: 80px;
}

.tableRow th:nth-child(3),
.tableRow td:nth-child(3) {
  width: 320px;
  cursor: pointer;
}

.tableRow th:nth-child(5),
.tableRow td:nth-child(5) {
  width: 240px;
}

.tableRow th:nth-child(6),
.tableRow td:nth-child(6) {
  width: 240px;
}

.tableRow th:nth-child(8),
.tableRow td:nth-child(8) {
  width: 240px;
}

.tableRow th:nth-child(9),
.tableRow td:nth-child(9) {
  width: 240px;
}

.tableRow th:last-child,
.tableRow td:last-child {
  width: 480px;
}

.tableRow {
  cursor: auto;
}

.tableRow:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.table {
  display: block;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  position: relative;
  min-height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-collapse: collapse;
  table-layout: fixed;
}

.table tbody,
.table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table thead {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.filterMenu {
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  border-radius: 4px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  max-width: 200px;
  max-height: 200px;
  overflow: auto;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.filterMenu div {
  padding: 4px 8px 4px 4px !important;
  gap: 4px;
}
