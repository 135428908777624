.divider {
    background: #DFEAF1;
    width: 100%;
    height: 1px;
}

.Hdivider {
    background: #DFEAF1;
    width: 1px;
    height: 100%;
}