.backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  cursor: auto !important;
  visibility: hidden;
  overflow: hidden;
}

.container {
  width: 1000px;
  max-width: 60vw;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
  transform: translateX(600px);
  transition: all 200ms;
  cursor: auto !important;
  overflow: auto;
}

.display {
  visibility: visible;
}

.display .container {
  transform: translateX(0);
}

.tag {
  background-color: #ddd;
  border-radius: 32px;
}

.tagsSelectionContainer {
  position: absolute;
  top: 32px;
  width: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 100;
  max-height: 160px;
  overflow: auto;
  visibility: hidden;
}

.tagSelection {
  padding: 8px 12px;
  user-select: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: black;
}

.tagSelection:hover {
  background: #eee;
}

.selectTagContainer {
  position: relative;
  width: 320px;
}

.selectTagContainer:focus-within .tagsSelectionContainer,
.selectTagContainer:active .tagsSelectionContainer  {
  visibility: visible;
}

.input {
  font-size: 18px;
  padding: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font-family: inherit;
}

.bottomContainer {
  position: sticky;
  bottom: 0;
  background-color: white;
}
